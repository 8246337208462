import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/mobilecontrols.css";

// Funkcija skirta mobiliųjų įrenginių valdymo mygtukų atvaizdavimui.
const MobileControls = ({ config }) => {
  const intervalRef = useRef(null);

  const handleButtonDown = (direction) => {
    const event = new CustomEvent("mobileMove", {
      detail: {
        forward: direction === "forward",
        backward: direction === "backward",
      },
    });
    window.dispatchEvent(event);

    intervalRef.current = setInterval(() => {
      window.dispatchEvent(event);
    }, 16);
  };

  const handleButtonUp = () => {
    clearInterval(intervalRef.current);
    const event = new CustomEvent("mobileMove", {
      detail: {
        forward: false,
        backward: false,
      },
    });
    window.dispatchEvent(event);
  };

  return (
    <div className="mobile-controls">
      <div
        className="control-button forward"
        onMouseDown={() => handleButtonDown("forward")}
        onMouseUp={handleButtonUp}
        onTouchStart={() => handleButtonDown("forward")}
        onTouchEnd={handleButtonUp}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
      <div
        className="control-button backward"
        onMouseDown={() => handleButtonDown("backward")}
        onMouseUp={handleButtonUp}
        onTouchStart={() => handleButtonDown("backward")}
        onTouchEnd={handleButtonUp}
      >
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </div>
  );
};

export default MobileControls;