import { Html } from '@react-three/drei';

// Funkcija skirta anotacijos komponento atvaizdavimui.
const Annotation = ({ position, onClick, isOpen }) => {
  return (
    <Html position={position}>
      <div className={`annotation ${isOpen ? 'hidden' : ''}`} onClick={onClick}>
        <div className="annotation-icon">i</div>
      </div>
    </Html>
  );
};

export default Annotation;
