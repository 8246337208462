import { useThree, useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { useRef, useEffect, useState, useCallback } from "react";
import { Euler, Vector3, Box3, Quaternion, Clock } from "three";

function CameraController({
  boxSize,
  boxPosition,
  slowDownDistance,
  defaultPosition,
  defaultRotation,
}) {
  const { gl } = useThree();
  const cameraRef = useRef();
  const boxRef = useRef(new Box3());
  const moveSpeed = 0.5;
  const rotationSpeed = 0.002;
  const keysPressed = useRef({
    KeyW: false,
    KeyS: false,
    KeyA: false,
    KeyD: false,
  });

  const [targetPosition, setTargetPosition] = useState(null);
  const [targetRotation, setTargetRotation] = useState(null);
  const transitionDuration = 500;
  const transitionStart = useRef(null);
  const touchStartRef = useRef(null);
  const touchMoveRef = useRef(null);

  const clock = useRef(new Clock());
  const maxVerticalRotation = Math.PI / 10;

  // Funkcija, kuri skirta perkelti kameros pozicija į norimą vietą, kai yra paspaudžiamas anotacijos mygtukas
  useEffect(() => {
    const handleAnnotationClick = (event) => {
      const { position, rotation } = event.detail;
      setTargetPosition(position);
      setTargetRotation(rotation);
      transitionStart.current = Date.now();
    };

    window.addEventListener("annotationClick", handleAnnotationClick);

    return () => {
      window.removeEventListener("annotationClick", handleAnnotationClick);
    };
  }, []);

  // Funkcija, skirta apdoroti lietimo įvykius (touch events)
  const handleTouchStart = useCallback((event) => {
    touchStartRef.current = event.touches[0];
  }, []);

  const handleTouchMove = useCallback(
    (event) => {
      if (touchStartRef.current && cameraRef.current) {
        const touchStart = touchStartRef.current;
        const touchEnd = event.touches[0];
        const deltaX = touchEnd.clientX - touchStart.clientX;
        const deltaY = touchEnd.clientY - touchStart.clientY;
        const euler = new Euler(0, 0, 0, "YXZ");
        euler.setFromQuaternion(cameraRef.current.quaternion);
        euler.y -= deltaX * rotationSpeed;
        euler.x -= deltaY * rotationSpeed;

        // Apriboti vertikalų sukimąsi
        euler.x = Math.max(-maxVerticalRotation, Math.min(maxVerticalRotation, euler.x));

        cameraRef.current.quaternion.setFromEuler(euler);
        touchStartRef.current = touchEnd;
      }
    },
    [rotationSpeed, maxVerticalRotation]
  );

  const handleTouchEnd = useCallback(() => {
    touchStartRef.current = null;
  }, []);

  useEffect(() => {
    const domElement = gl.domElement;
    domElement.addEventListener("touchstart", handleTouchStart);
    domElement.addEventListener("touchmove", handleTouchMove);
    domElement.addEventListener("touchend", handleTouchEnd);

    return () => {
      domElement.removeEventListener("touchstart", handleTouchStart);
      domElement.removeEventListener("touchmove", handleTouchMove);
      domElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  // Funkcija, skirta atnaujinti kameros poziciją ir orientaciją
  useFrame(() => {
    const deltaTime = clock.current.getDelta();

    if (targetPosition && cameraRef.current) {
      const elapsed = Date.now() - transitionStart.current;
      const t = Math.min(elapsed / transitionDuration, 1);

      cameraRef.current.position.lerp(new Vector3(...targetPosition), t);
      cameraRef.current.quaternion.slerp(
        new Quaternion().setFromEuler(new Euler(...targetRotation)),
        t
      );

      if (t === 1) {
        setTargetPosition(null);
        setTargetRotation(null);
      }
    }

    moveCamera(deltaTime);
  });

  // Funkcija, skirta nustatyti pradinę kameros poziciją ir orientaciją, kada yra pakeičiamas modelis
  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(...defaultPosition);
      cameraRef.current.quaternion.setFromEuler(new Euler(...defaultRotation));
    }
    updateBox();
  }, [defaultPosition, defaultRotation, boxSize, boxPosition]);

  // Funkcija, skirta atnaujinti kameros judėjima, pačioje dėžėje, kuri riboja judėjimo erdve
  const updateBox = () => {
    boxRef.current.setFromCenterAndSize(
      new Vector3(...boxPosition),
      new Vector3(...boxSize)
    );
  };

  const mobileControls = useRef({
    forward: false,
    backward: false,
  });

  // Funkcija, skirta apdoroti pelės judėjima
  const handleMouseMove = useCallback(
    (event) => {
      if (event.buttons > 0 && cameraRef.current) {
        const deltaX = -event.movementX;
        const deltaY = -event.movementY;
        const euler = new Euler(0, 0, 0, "YXZ");
        euler.setFromQuaternion(cameraRef.current.quaternion);
        euler.y -= deltaX * rotationSpeed;
        euler.x -= deltaY * rotationSpeed;

        // Apriboti vertikalų sukimąsi
        euler.x = Math.max(-maxVerticalRotation, Math.min(maxVerticalRotation, euler.x));

        cameraRef.current.quaternion.setFromEuler(euler);
      }
    },
    [rotationSpeed, maxVerticalRotation]
  );

  // Funkcija, skirta apdoroti mobilaus įrenginio judesio įvykius
  const handleMobileMove = useCallback((event) => {
    const { forward, backward } = event.detail;
    mobileControls.current.forward = forward;
    mobileControls.current.backward = backward;
  }, []);

  // Klaviatūros mygtukų paspaudimo apdorojimo funkcija
  const handleKeyDown = useCallback((event) => {
    keysPressed.current[event.code] = true;
  }, []);

  // Klaviatūros mygtukų atleidimo apdorojimo funkcija
  const handleKeyUp = (event) => {
    keysPressed.current[event.code] = false;
  };

  // Kameros judėjimo funkcija, kuri naudoja klaviaturos klavišus
  const moveCamera = (deltaTime) => {
    if (!cameraRef.current) return;

    const movementVector = new Vector3();
    const frontVector = new Vector3(0, 0, -1);
    const sideVector = new Vector3(1, 0, 0);
    frontVector.applyQuaternion(cameraRef.current.quaternion);
    sideVector.applyQuaternion(cameraRef.current.quaternion);

    frontVector.y = 0;
    sideVector.y = 0;
    frontVector.normalize();
    sideVector.normalize();

    const moveSpeed = 0.5;

    if (keysPressed.current.KeyW || mobileControls.current.forward)
      movementVector.add(frontVector.multiplyScalar(moveSpeed * deltaTime));
    if (keysPressed.current.KeyS || mobileControls.current.backward)
      movementVector.add(frontVector.multiplyScalar(-moveSpeed * deltaTime));
    if (keysPressed.current.KeyA)
      movementVector.add(sideVector.multiplyScalar(-moveSpeed * deltaTime));
    if (keysPressed.current.KeyD)
      movementVector.add(sideVector.multiplyScalar(moveSpeed * deltaTime));

    const newPos = cameraRef.current.position.clone().add(movementVector);

    if (boxRef.current.containsPoint(newPos)) {
      cameraRef.current.position.copy(newPos);
    } else {
      const currentPos = cameraRef.current.position;
      const deltaPos = newPos.sub(currentPos);

      if (deltaPos.x !== 0) {
        const testPosX = currentPos.clone().add(new Vector3(deltaPos.x, 0, 0));
        if (boxRef.current.containsPoint(testPosX)) {
          currentPos.x = testPosX.x;
        }
      }

      if (deltaPos.z !== 0) {
        const testPosZ = currentPos.clone().add(new Vector3(0, 0, deltaPos.z));
        if (boxRef.current.containsPoint(testPosZ)) {
          currentPos.z = testPosZ.z;
        }
      }
    }
  };

  // Funkcija, skirta registruoti pelės ir klaviatūros įvykius
  useEffect(() => {
    const domElement = gl.domElement;
    domElement.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    window.addEventListener("mobileMove", handleMobileMove);

    return () => {
      domElement.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("mobileMove", handleMobileMove);
    };
  }, [handleMouseMove, handleMobileMove]);

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault
      fov={60}
      near={0.07}
      far={100}
    />
  );
}

export default CameraController;