import React, { useMemo } from "react";
import "../styles/overlay.css";

function Overlay({ onOverlayClick, language }) {
  const text = useMemo(() => ({
    instructions: language === "LT" ? "Instrukcija" : "Instructions",
    navigation: language === "LT" ? "Naršymui naudokite šiuos valdiklius:" : "Use these controls for navigation:",
    clickToStart: language === "LT" ? "Spustelėkite bet kur, kad pradėtumėte" : "Click anywhere to start"
  }), [language]);

  return (
    <div className="overlay" onClick={onOverlayClick}>
      <div className="overlay-content">
        <h2>{text.instructions}</h2>
        <p>{text.navigation}</p>
        <div className="icon-container">
          <div className="icon icon-wasd"></div>
          <div className="icon icon-mouse"></div>
        </div>
        <p>{text.clickToStart}</p>
      </div>
    </div>
  );
}

export default Overlay;
