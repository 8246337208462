import React from "react";
import "../styles/navigationbutton.css";

function NavigationButton({ name }) {
  return (
    <div className="navigation-button">
      <div className="name-container">
        <span className="name">{name}</span>
      </div>
    </div>
  );
}

export default NavigationButton;