import React from 'react';
import '../styles/annotation.css';

const AnnotationOverlay = ({ content, onClose }) => {
  const renderContent = () => {
    if (typeof content === 'string') {
      if (content.endsWith('.png') || content.endsWith('.jpg')) {
        return <img src={content} alt="Annotation" />;
      } else if (content.includes('<video')) {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
      } else if (content.startsWith('http://') || content.startsWith('https://')) {
        return (
          <div className="annotation-url">
            <a href={content} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          </div>
        );
      } else {
        return <div className="annotation-text" dangerouslySetInnerHTML={{ __html: content }} />;
      }
    }
    return null;
  };

  return (
    <div className="annotation-overlay">
      <div
        className={`annotation-box ${
          content && content.includes('<iframe') ? 'video-overlay' : 'image-overlay'
        }`}
      >
        <button className="annotation-close" onClick={onClose}>
          ×
        </button>
        <div className="annotation-content">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AnnotationOverlay;