import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faYoutube, faInstagram, faLinkedinIn, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import "../styles/menu.css";
import Overlay from "./overlay.js";

const Menu = ({
  models,
  selectedModel,
  setSelectedModelIndex,
  language,
  toggleLanguage,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Funkcija, skirta perjungti išskleidžiamąjį meniu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Funkcija, skirta apdoroti modelio pasirinkimą
  const handleModelClick = (index) => {
    setSelectedModelIndex(index);
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  // Funkcija, skirta atidaryti instrukcijų langą
  const handleInstructionsClick = () => {
    setIsOverlayVisible(true);
    setIsMobileMenuOpen(false);
  };

  // Funkcija, skirta uždaryti instrukcijų langą
  const handleOverlayClick = () => {
    setIsOverlayVisible(false);
  };

  // Funkcija skirta perjungti į mobilaus įrenginio būseną
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const categories = {
    category1: {
      name: language === "LT" ? "Centriniai rūmai" : "Main",
      models: ["model1", "model2", "model4", "model5", "model8", "model6", "model9", "model3"],
    },
    category2: {
      name: language === "LT" ? "Sveikatos priežiūros fakultetas" : "SPF",
      models: ["spf_model6", "spf_model1", "spf_model4", "spf_model5", "spf_model3", "spf_model2"],
    },
  };

  return (
    <>
      <div className="menu">
        <div className="logo">
          <img
            src="/siauliu-valstybine-kolegija-logotipas-horizontalus.png"
            alt="Logo"
          />
        </div>
        <div className="menu-items">
          <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
            <div className="dropdown-toggle" onClick={toggleDropdown}>
              {language === "LT" ? "Erdvės" : "Spaces"}{" "}
              <span className="arrow">▾</span>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-row">
                {Object.entries(categories).slice(0, 2).map(([categoryKey, category]) => (
                  <div key={categoryKey} className="category-column">
                    <div className="category-name">{category.name}</div>
                    {category.models.map((modelKey) => (
                      <div
                        key={modelKey}
                        className="dropdown-item"
                        onClick={() => handleModelClick(Object.keys(models).indexOf(modelKey))}
                      >
                        {models[modelKey].name[language]}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="menu-item" onClick={handleInstructionsClick}>
            {language === "LT" ? "Instrukcija" : "Instructions"}
          </div>
          <div className="menu-item language-toggle" onClick={toggleLanguage}>
            {language}
          </div>
        </div>
        <div className="sandwich-button" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </div>
      </div>
      <div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-items">
          <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
            <div className="dropdown-toggle" onClick={toggleDropdown}>
              {language === "LT" ? "Erdvės" : "Spaces"}{" "}
              <span className="arrow">▾</span>
            </div>
            <div className="dropdown-menu">
              {Object.entries(categories).map(([categoryKey, category]) => (
                <div key={categoryKey} className="category-column">
                  <div className="category-name">{category.name}</div>
                  {category.models.map((modelKey) => (
                    <div
                      key={modelKey}
                      className="dropdown-item"
                      onClick={() => handleModelClick(Object.keys(models).indexOf(modelKey))}
                    >
                      {models[modelKey].name[language]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="mobile-menu-item" onClick={handleInstructionsClick}>
            {language === "LT" ? "Instrukcija" : "Instructions"}
          </div>
          <div className="mobile-menu-item language-toggle" onClick={toggleLanguage}>
            {language}
          </div>
          <div className="social-media-icons">
            <a href="https://www.youtube.com/user/svakoTV/featured" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.instagram.com/siauliuvalstybinekolegija/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://lt.linkedin.com/company/%C5%A1iauli%C5%B3-valstybin%C4%97-kolegija" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="https://www.facebook.com/Siauliuvalstybinekolegija" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </div>
          <div className="copyright">
            {language === "LT"
              ? "© 2024 Šiaulių valstybinė kolegija. Visos teisės saugomos."
              : "© 2024 Šiaulių valstybinė kolegija. All rights reserved."}
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="social-media-icons">
          <a href="https://www.youtube.com/user/svakoTV/featured" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.instagram.com/siauliuvalstybinekolegija/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://lt.linkedin.com/company/%C5%A1iauli%C5%B3-valstybin%C4%97-kolegija" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a href="https://www.facebook.com/Siauliuvalstybinekolegija" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
        <div className="copyright">
          {language === "LT"
            ? "© 2024 Šiaulių valstybinė kolegija. Visos teisės saugomos."
            : "© 2024 Šiaulių valstybinė kolegija. All rights reserved."}
        </div>
      </div>
      {isOverlayVisible && (
        <Overlay onOverlayClick={handleOverlayClick} language={language} />
      )}
    </>
  );
};

Menu.propTypes = {
  models: PropTypes.object.isRequired,
  selectedModel: PropTypes.string.isRequired,
  setSelectedModelIndex: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
};

Menu.defaultProps = {
  models: {},
};

export default Menu;
