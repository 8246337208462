import React from "react";
import "../styles/loadingmodelcomponent.css";

const LoadingModelComponent = ({ progress }) => {
  return progress < 100 ? (
    <div className="loading-model-component">
      <div className="pulsating-dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  ) : null;
};

export default LoadingModelComponent;