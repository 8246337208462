import { useState, useEffect, Suspense, useRef, useCallback } from "react";
import React from "react";
import { debounce } from "lodash";
import { Canvas } from "@react-three/fiber";
import { Splat, Box, Environment } from "@react-three/drei";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CameraController from "./components/cameracontroller.js";
import Overlay from "./components/overlay.js";
import NavigationButton from "./components/navigationbutton.js";
import Menu from "./components/menu.js";
import Annotation from "./components/annotation.js";
import AnnotationOverlay from "./components/annotationoverlay.js";
import MobileControls from "./components/mobilecontrols.js";
import LoadingModelComponent from "./components/loadingmodelcomponent.js";
import "./styles/styles.css";
import "./styles/annotation.css";
import "./styles/mobilecontrols.css";



const modelsConfig = {
  model1: {
    name: { LT: "Įėjimas", EN: "Entrance" },
    src: "/models/main.splat",
    position: [0, 0, 0],
    rotation: [0.01, 2.6, 0],
    scale: 1,
    boxSize: [0.3, 0.2, 2],
    boxPosition: [0, 0, 0],
    cameraPosition: [-0.1, 0.05, 0.5],
    cameraRotation: [0, -Math.PI / 2, 0],
    annotations: [
      {
        position: [0.5, 0.1, 0.255],
        content: "/images/Kabinetai.jpg",
        cameraPosition: [0, 0.05, 0.255],
        cameraRotation: [0, Math.PI / -2, 0],
      },
    ],
  },
  model2: {
    name: { LT: "2 a. koridorius", EN: "2nd floor corridor" },
    src: "/models/model5.splat",
    position: [2, 2.02, -1.9],
    rotation: [-0.01, 3.9, 0],
    scale: 1,
    boxSize: [1.7, 0.15, 0.15],
    boxPosition: [1.87, 2.1, -1.95],
    cameraPosition: [2, 2.1, -2],
    cameraRotation: [0, Math.PI / -2, 0],
    annotations: [
      {
        position: [4, 2, -2],
        content: {
          LT: "/images/info_lt.jpg",
          EN: "/images/info_en.jpg"
        },
        cameraPosition: [2.5, 2.1, -1.95],
        cameraRotation: [0, Math.PI / -2, 0],
      },
    ],
  },
  model4: {
    name: { LT: "1 a. koridorius", EN: "1nd floor corridor" },
    src: "/models/model4.splat",
    position: [1.85, 2.1, -2],
    rotation: [0, -0.3, 0.01],
    scale: 2,
    boxSize: [0.001, 0.05, 2],
    boxPosition: [1.95, 2.09, -2.2],
    cameraPosition: [1.95, 2.08, -1.2],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  model5: {
    name: { LT: "MT studija", EN: "MT studio" },
    src: "/models/model6.splat",
    position: [0.5, 0, 0],
    rotation: [-0.1, 0, -0.05],
    scale: 1,
    boxSize: [1, 1, 0.5],
    boxPosition: [0.7, 0, 0.5],
    cameraPosition: [0.6, 0, 0.7],
    cameraRotation: [0, 0, 0.05],
    annotations: [
      {
        position: [0.6, 0, -0.5],
        content: '<video width="95%" height="100%" controls autoplay style="display: block; margin: auto;"><source src="/videos/MT.mp4" type="video/mp4"></video>',
        cameraPosition: [0.5, 0, 0.5],
        cameraRotation: [0, Math.PI / -50, 0],
      },
    ],
  },
  model8: {
    name: { LT: "Kompiuterių a.", EN: "Computer class" },
    src: "/models/model8.splat",
    position: [2, 2, -2],
    rotation: [-0.1, 0, 0],
    scale: 1,
    boxSize: [0.8, 0.05, 0.2],
    boxPosition: [2, 2, -1.7],
    cameraPosition: [1.9, 2, -1.7],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  model6: {
    name: { LT: "Biblioteka", EN: "Library" },
    src: "/models/biblioteka.splat",
    position: [2, 2, -2],
    rotation: [0.01, 0.6, 0],
    scale: 1,
    boxSize: [0.25, 0.1, 0.8],
    boxPosition: [1.9, 2.1, -2.1],
    cameraPosition: [1.9, 2.08, -2],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  model9: {
    name: { LT: "Aktų salė", EN: "School hall" },
    src: "/models/model9.splat",
    position: [2, 2.1, -2.5],
    rotation: [0, 0.96, 0],
    scale: 1,
    boxSize: [0.25, 0.1, 0.2],
    boxPosition: [1.9, 2.1, -2.1],
    cameraPosition: [1.9, 2.08, -2],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  model3: {
    name: { LT: "Studentų zona", EN: "Student zone" },
    src: "/models/model3.splat",
    position: [2, 2, -2],
    rotation: [-0.03, -0.15, -0.05],
    scale: 1,
    boxSize: [0.25, 0.05, 1.5],
    boxPosition: [2, 2.09, -2],
    cameraPosition: [1.9, 2.08, -2],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },



  spf_model6: {
    name: { LT: "Įėjimas", EN: "Entrance" },
    src: "/models/SPF/spfmain.splat",
    position: [0, 0, 0],
    rotation: [0, -0.1, 0],
    scale: 1,
    boxSize: [0.2, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0.1, 0, 0],
    cameraRotation: [0, 0, 0],
    annotations: [
      {
        position: [0.15, 0, -1],
        content: "/images/SPF_Kabinetai.jpg",
        cameraPosition: [0.2, 0, -0.5],
        cameraRotation: [0, Math.PI / 110, 0],
      },
    ],
  },
  spf_model7: {
    name: { LT: "Studentų zona", EN: "Student zone" },
    src: "/models/SPF/studentuzona.splat",
    position: [0, 0, 0],
    rotation: [0, -1.4, 0],
    scale: 1,
    boxSize: [0.2, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0.1, 0, 0],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  spf_model1: {
    name: { LT: "Kosmetologija 1", EN: "Cosmetology 1" },
    src: "/models/SPF/model1.splat",
    position: [0, 0, 0],
    rotation: [0, -0.1, 0.01],
    scale: 1,
    boxSize: [0.2, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0.1, 0, 0],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
  spf_model4: {
    name: { LT: "Kosmetologija 2", EN: "Cosmetology 2" },
    src: "/models/SPF/model5.splat",
    position: [0, 0, 0],
    rotation: [0, -0.1, 0.01],
    scale: 1,
    boxSize: [0.2, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0.1, 0, -0.5],
    cameraRotation: [0, -3.2, 0],
    annotations: [
      {
        position: [-0.3, 0, 0.7],
        content: '<video width="95%" height="100%" controls autoplay style="display: block; margin: auto;"><source src="/videos/Kosmetologija.mp4" type="video/mp4"></video>',
        cameraPosition: [0.1, 0, 0.48],
        cameraRotation: [0, Math.PI / 1.5, 0],
      },
    ],
  },
  spf_model5: {
    name: { LT: "Kineziterapija", EN: "Physical therapy" },
    src: "/models/SPF/model2.splat",
    position: [0, 0, 0],
    rotation: [-0.03, 0.61, 0.01],
    scale: 1,
    boxSize: [0.5, 1, 1],
    boxPosition: [-0.1, 0, 0],
    cameraPosition: [0.1, 0, 0],
    cameraRotation: [0, 3.2, 0],
    annotations: [
      {
        position: [-0.4, 0, 0.25],
        content: '<video width="95%" height="100%" controls autoplay style="display: block; margin: auto;"><source src="/videos/KNZ.mp4" type="video/mp4"></video>',
        cameraPosition: [0.1, 0, 0.25],
        cameraRotation: [0, Math.PI / 2, 0],
      },
    ],
  },
  spf_model3: {
    name: { LT: "Odontologija", EN: "Odontology" },
    src: "/models/SPF/model3.splat",
    position: [0, 0, 0],
    rotation: [0, 0.5, 0.01],
    scale: 1,
    boxSize: [0.6, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0, 0, 0.5],
    cameraRotation: [0, 0, 0],
    annotations: [
      {
        position: [-0.05, 0, -0.3],
        content: '<video width="95%" height="100%" controls autoplay style="display: block; margin: auto;"><source src="/videos/Odontologija.mp4" type="video/mp4"></video>',
        cameraPosition: [-0.05, 0, 0.25],
        cameraRotation: [0, Math.PI / 100, 0],
      },
    ],
  },
  spf_model2: {
    name: { LT: "Sporto salė", EN: "Sport hall" },
    src: "/models/SPF/model4.splat",
    position: [0, 0, 0],
    rotation: [0, 0.8, 0.01],
    scale: 1,
    boxSize: [0.6, 1, 1],
    boxPosition: [0.1, 0, 0],
    cameraPosition: [0.1, 0, 0],
    cameraRotation: [0, 0, 0],
    annotations: [],
  },
};

const App = () => {
  const cameraRef = useRef();
  const [selectedModelIndex, setSelectedModelIndex] = useState(0);
  const modelKeys = Object.keys(modelsConfig);
  const selectedModel = modelKeys[selectedModelIndex];
  const {
    src,
    position,
    rotation,
    scale,
    boxSize,
    boxPosition,
    cameraPosition,
    cameraRotation,
  } = modelsConfig[selectedModel];
  const slowDownDistance = 0.1;
  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const [language, setLanguage] = useState("LT");
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  // Funckija krauti modelius
  useEffect(() => {
    const simulateLoading = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setLoadingProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 500);
    };

    simulateLoading();
  }, [selectedModel]);

  const handleLoadingProgress = useCallback(
    debounce((xhr) => {
      if (xhr.lengthComputable) {
        const loadingProgress = Math.round((xhr.loaded / xhr.total) * 100);
        setLoadingProgress(loadingProgress);
      }
    }, 200),
    [debounce]
  );

  // Funkcija, skirta pasikeisti kalbą
  const toggleLanguage = () => {
    setLanguage(language === "LT" ? "EN" : "LT");
  };

  // Funkcija, skirta paslėpti vartotojo sąsajos (overlay) elementą kuris skirtas parodyti instrukcija
  const handleOverlayClick = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("hidden");
  };

  // Funkcija, skirta judinti kameros pozicija vartotojams kurie naudoja mobilius įrenginius
  const mobileControlsConfig = {
    forwardSpeed: 15,
    backwardSpeed: 15,
  };

  // Funkcija, skirta pereiti prie ankstesnio modelio (go backwards), kuris yra naudojamas su navigacijos elemente - kairė rodyklė
  const handlePrevModel = () => {
    setSelectedModelIndex((prevIndex) =>
      prevIndex === 0 ? modelKeys.length - 1 : prevIndex - 1
    );
  };

  // Funkcija, skirta pereiti prie kito modelio (go foward), kuris taip pat yra naudojamas su navigacijos elemente - dešinė rodyklė
  const handleNextModel = () => {
    setSelectedModelIndex((prevIndex) =>
      prevIndex === modelKeys.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Funkcija, skirta apdoroti mygtuko/anotacijos paspaudimą, kuris apdaroroja anotacijos nustatytas funckijas, kaip anotacijos 3D erdvės parametrus
  const handleAnnotationClick = (annotation) => {
    setSelectedAnnotation(annotation);
    const { cameraPosition, cameraRotation } = annotation;
    const event = new CustomEvent("annotationClick", {
      detail: {
        position: cameraPosition,
        rotation: cameraRotation,
      },
    });
    window.dispatchEvent(event);
  };

  // Funkcija, skirta uždaryti mygtuko/anotacijos peržiūrą
  const handleAnnotationClose = () => {
    setSelectedAnnotation(null);
  };

  // Funkcija, skirta parodyti anotacijos turinį, tai būtų string (žodžiai), nuotraukos .png ir .jpg formatu, <iframe> imbeded link ir html turinį
  const renderAnnotationContent = (content) => {
    if (typeof content === "object") {
      const imagePath = content[language];
      if (imagePath) {
        return imagePath;
      }
    } else if (typeof content === "string") {
      if (content.endsWith(".png") || content.endsWith(".jpg")) {
        return content;
      } else if (content.includes("<iframe")) {
        return content;
      } else {
        return content;
      }
    }
    return null;
  };

  // Efektas/animacija, skirtas rodyti navigacijos mygtukus po 1 sekundės, kai pasileidžia virtualus turas
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsNavigationVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LoadingModelComponent progress={loadingProgress} />
      <Overlay onOverlayClick={handleOverlayClick} language={language} />
      <Canvas>
        <Suspense fallback={null}>
          <CameraController
            ref={cameraRef}
            boxSize={boxSize}
            boxPosition={boxPosition}
            slowDownDistance={slowDownDistance}
            defaultPosition={cameraPosition}
            defaultRotation={cameraRotation}
          />
          <Splat src={src} position={position} rotation={rotation} scale={scale} onProgress={handleLoadingProgress} />
          <Box
            args={boxSize}
            position={boxPosition}
            rotation={[0, 0, 0]}
            material-opacity={0}
            material-transparent
          />
          <Environment preset="city" />
          {modelsConfig[selectedModel].annotations.map((annotation, index) => (
            <Annotation
              key={index}
              position={annotation.position}
              onClick={() => handleAnnotationClick(annotation)}
              isOpen={selectedAnnotation === annotation}
            />
          ))}
        </Suspense>
      </Canvas>
      <MobileControls config={mobileControlsConfig} />
      <div className={`navigation ${isNavigationVisible ? "visible" : ""}`}>
        <div className="arrow-container left" onClick={handlePrevModel}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <NavigationButton name={modelsConfig[selectedModel].name[language]} />
        <div className="arrow-container right" onClick={handleNextModel}>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
      <Menu
        models={modelsConfig}
        selectedModel={selectedModel}
        setSelectedModelIndex={setSelectedModelIndex}
        language={language}
        toggleLanguage={toggleLanguage}
      />
      {selectedAnnotation && (
        <AnnotationOverlay
          content={renderAnnotationContent(selectedAnnotation.content)}
          onClose={handleAnnotationClose}
        />
      )}
    </>
  );
};

export default App;
